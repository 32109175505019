<template>
  <section>
    <div class="d-flex align-center mb-6">
      <div class="text-h5 font-weight-bold mr-4">
        <span class="saira">Informações gerais</span>
      </div>
    </div>

    <v-row>
      <v-col cols="12" sm="10" md="9" lg="8" xl="7">
        <v-card class="pa-8 mb-4" color="surface" tile>
          <v-list class="transparent pa-0">
            <v-list-item v-for="(item, i) in details" class="px-0" :key="i">
              <div class="d-flex flex-column flex-grow-1 justify-center">
                <div
                  class="d-flex justify-space-between flex-grow-1 align-center px-1"
                >
                  <span
                    class="text--body-3 font-weight-bold"
                    v-text="item.label"
                  />
                  <span class="text--body-3" v-text="item.value" />
                </div>
                <v-divider />
              </div>
            </v-list-item>
          </v-list>
        </v-card>

        <div class="d-flex align-center">
          <span class="mr-4 text-body-2 subtext--text"
            >Confira as regras do jogo</span
          >

          <v-btn
            class="rounded-l-0 rounded-r-lg text-none"
            color="accent"
            :to="`/league-details/${selected.id}/rules`"
          >
            <v-icon left>mdi-file</v-icon>
            Ver regras
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from "@/utils";

export default {
  computed: {
    ...mapState(["selected"]),

    _isSolo: function () {
      return this.selected.x < 2;
    },

    details() {
      return [
        {
          label: "Status",
          value: this.selected.isActive ? "Ativa" : "Inativa",
        },
        {
          label: "Modo",
          value: this._isSolo ? "Solo" : `Equipe (x${this.selected.x})`,
        },
        {
          label: "Inscrições",
          value: `${formatDate(
            this.selected.enrollmentsInitDate,
            "DD/MM/YYYY"
          )} ~ ${formatDate(this.selected.enrollmentsFinalDate, "DD/MM/YYYY")}`,
        },
        {
          label: "Início dos jogos",
          value: formatDate(this.selected.tournamentInitDate, "DD/MM/YYYY"),
        },
        {
          label: "Grande Final",
          value: formatDate(this.selected.tournamentFinalDate, "DD/MM/YYYY"),
        },
        {
          label: this._isSolo ? "Jogadores Inscritos" : "Equipes Inscritas",
          value: this.selected.teams.length,
        },
      ];
    },
  },
};
</script>

<style></style>
